import React, { useState } from "react";
import WipeBtn from "../../components/atoms/buttons/WipeBtn";
import ReachOut from "../../components/atoms/CTA/ReachOut";

import { useLanguage } from "../../languageContext";

const StaffingAddValue = ({
  titles,
  descriptions,
  slideOneImg,
  slideTwoImg,
  slideThreeImg,
  slideFourImg,
}) => {
  const currentLanguage = useLanguage();
  const [showSlideOne, setShowSlideOne] = useState(true);
  const [showSlideTwo, setShowSlideTwo] = useState(false);
  const [showSlideThree, setShowSlideThree] = useState(false);
  const [showSlideFour, setShowSlideFour] = useState(false);

  const changeToSlideOne = () => {
    setShowSlideTwo(false);
    setShowSlideThree(false);
    setShowSlideOne(true);
    setShowSlideFour(false);
  };

  const changeToSlideTwo = () => {
    setShowSlideOne(false);
    setShowSlideThree(false);
    setShowSlideTwo(true);
    setShowSlideFour(false);
  };

  const changeToSlideThree = () => {
    setShowSlideOne(false);
    setShowSlideTwo(false);
    setShowSlideThree(true);
    setShowSlideFour(false);
  };

  const changeToSlideFour = () => {
    setShowSlideOne(false);
    setShowSlideTwo(false);
    setShowSlideThree(false);
    setShowSlideFour(true);
  };

  return (
    <div className="section m-top-10 bg-light-blue">
      <div className="container">
        <div className="join-goken-section">
          <div className="info-container">
            <h2 className="heading">Where do we add value?</h2>
            <div className="slide-index m-top-20px">
              <div
                role="button"
                tabIndex={0}
                className={
                  showSlideOne
                    ? `slide title active-${
                        currentLanguage ? currentLanguage : "en"
                      }`
                    : "slide title"
                }
                onClick={changeToSlideOne}
              >
                {titles[0]}
                {showSlideOne && (
                  <div
                    className={`underline-${
                      currentLanguage ? currentLanguage : "en"
                    }`}
                  ></div>
                )}
              </div>

              <div
                role="button"
                tabIndex={0}
                className={
                  showSlideTwo
                    ? `slide title active-${
                        currentLanguage ? currentLanguage : "en"
                      }`
                    : "slide title"
                }
                onClick={changeToSlideTwo}
              >
                {titles[1]}
                {showSlideTwo && (
                  <div
                    className={`underline-${
                      currentLanguage ? currentLanguage : "en"
                    }`}
                  ></div>
                )}
              </div>
              <div
                role="button"
                tabIndex={0}
                className={
                  showSlideThree
                    ? `slide title active-${
                        currentLanguage ? currentLanguage : "en"
                      }`
                    : "slide title"
                }
                onClick={changeToSlideThree}
              >
                {titles[2]}
                {showSlideThree && (
                  <div
                    className={`underline-${
                      currentLanguage ? currentLanguage : "en"
                    }`}
                  ></div>
                )}
              </div>
              <div
                role="button"
                tabIndex={0}
                className={
                  showSlideFour
                    ? `slide title active-${
                        currentLanguage ? currentLanguage : "en"
                      }`
                    : "slide title"
                }
                onClick={changeToSlideFour}
              >
                {titles[3]}
                {showSlideFour && (
                  <div
                    className={`underline-${
                      currentLanguage ? currentLanguage : "en"
                    }`}
                  ></div>
                )}
              </div>
            </div>
          </div>
          <div className="slider text-center">
            {showSlideOne ? (
              <div className="slider-wrapper">
                <div className="image-wrapper">
                  <img
                    src={
                      slideOneImg
                        ? slideOneImg
                        : "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=751&q=80"
                    }
                    alt="Slide One"
                  ></img>
                </div>
                <h3
                  className={`title m-top-10px color-${
                    currentLanguage ? currentLanguage : "en"
                  }`}
                >
                  {titles[0]}
                </h3>
                <h4>{descriptions[0]}</h4>
              </div>
            ) : (
              ""
            )}
            {showSlideTwo ? (
              <div className="slider-wrapper">
                <div className="image-wrapper">
                  <img
                    src={
                      slideTwoImg
                        ? slideTwoImg
                        : "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=751&q=80"
                    }
                    alt="Slide Two"
                  ></img>
                </div>
                <h3
                  className={`title m-top-10px color-${
                    currentLanguage ? currentLanguage : "en"
                  }`}
                >
                  {titles[1]}
                </h3>
                <h4>{descriptions[1]}</h4>
              </div>
            ) : (
              ""
            )}

            {showSlideThree ? (
              <div className="slider-wrapper">
                <div className="image-wrapper">
                  <img
                    src={
                      slideThreeImg
                        ? slideThreeImg
                        : "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=751&q=80"
                    }
                    alt="Slide Three"
                  ></img>
                </div>
                <h3
                  className={`title m-top-10px color-${
                    currentLanguage ? currentLanguage : "en"
                  }`}
                >
                  {titles[2]}
                </h3>
                <h4>{descriptions[2]}</h4>
              </div>
            ) : (
              ""
            )}
            {showSlideFour ? (
              <div className="slider-wrapper">
                <div className="image-wrapper">
                  <img
                    src={
                      slideFourImg
                        ? slideFourImg
                        : "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=751&q=80"
                    }
                    alt="Slide Four"
                  ></img>
                </div>
                <h3
                  className={`title m-top-10px color-${
                    currentLanguage ? currentLanguage : "en"
                  }`}
                >
                  {titles[3]}
                </h3>
                <h4>{descriptions[3]}</h4>
              </div>
            ) : (
              ""
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <WipeBtn
                text={"Reach Out"}
                url={"/contactus/"}
                className={"green-border bg-green-btn green-btn"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffingAddValue;
